import React from 'react'
import { Flex, Box, Heading, Text, Image } from 'rebass'
import AspectRatio from 'react-aspect-ratio'
import Hero from './Hero'
import Link from './Link'
import IncludedIcon from '../../assets/icons/included.svg'
import NotIncludedIcon from '../../assets/icons/not-included.svg'
import { modelSlugToRoute } from '../../utils/links'

const Section = props => {
    switch (props.model.apiKey) {
        case 'hero':
            return (
                <Hero fluid={props.background.fluid}>
                    <Heading as="h1" variant="hero.heading">{props.heading}</Heading>
                    <Heading as="h2" variant="hero.subheading">{props.subheading}</Heading>
                    {props.actions.length > 0 && <Box my={5} mx={-2}>
                        {props.actions.map(action =>
                            <Link
                                key={action.id}
                                to={modelSlugToRoute(action.model.apiKey, action.slug)}
                                variant="button"
                                sx={{ px: 6, py: 2, mx: 2 }}
                            >
                                {action.name}
                            </Link>
                        )}
                    </Box>}
                </Hero>
            )

        case 'description':
            return (
                <Box variant="fixed" my={1}>
                    <Flex flexDirection={['column-reverse', 'row']}>
                        <Heading
                            flex={3}
                            as="h3"
                            variant='heading'
                            dangerouslySetInnerHTML={{
                                __html: props.descriptionNode.childMarkdownRemark.html,
                            }}
                        />
                        {props.image && (
                            <Flex
                                flex={1}
                                alignItems="center"
                                justifyContent="center"
                                mb={[4]}
                            >
                                <Image src={props.image.url} />
                            </Flex>
                        )}
                    </Flex>
                </Box>
            )

        case 'content':
            return (
                <Box variant="fixed" my={5}>
                    <Heading as="h3" variant='heading'>{props.title}</Heading>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: props.contentNode.childMarkdownRemark.html,
                        }}
                    />
                </Box>
            )

        case 'video':
            return (
                <Box variant="fixed" my={5}>
                    <AspectRatio ratio="560/315" style={{ maxWidth: '100%' }}>
                        <iframe
                            src={`https://www.youtube-nocookie.com/embed/${props.video.providerUid}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={props.video.title}
                        />
                    </AspectRatio>
                </Box>
            )

        case 'feature':
            return (
                <Box variant="fixed" my={5}>
                    <Heading as="h3" variant='heading'>{props.title}</Heading>
                    <Flex mx={[0, -2, -4]} flexDirection={['column', 'row']}>
                        {props.feature1Node.childMarkdownRemark.html && (
                            <Box
                                flex={1}
                                mx={[0, 2, 4]}
                                dangerouslySetInnerHTML={{
                                    __html: props.feature1Node.childMarkdownRemark.html,
                                }}
                            />
                        )}
                        {props.feature2Node.childMarkdownRemark.html && (
                            <Box
                                flex={1}
                                mx={[0, 2, 4]}
                                dangerouslySetInnerHTML={{
                                    __html: props.feature2Node.childMarkdownRemark.html,
                                }}
                            />
                        )}
                        {props.feature3Node.childMarkdownRemark.html && (
                            <Box
                                flex={1}
                                mx={[0, 2, 4]}
                                dangerouslySetInnerHTML={{
                                    __html: props.feature3Node.childMarkdownRemark.html,
                                }}
                            />
                        )}
                    </Flex>
                </Box>
            )

        case 'coverage':
            return (
                <Box variant="fixed" my={5}>
                    <Heading as="h3" variant='heading'>{props.title}</Heading>
                    <Flex mx={[0, -2, -3]} flexDirection={['column', 'row']}>
                        <Box
                            flex={1}
                            mx={[0, 2, 3]}
                            variant="card"
                        >
                            <Flex alignItems="center" mb={3}>
                                <Heading as="h5" variant='subheading' sx={{ flex: 1 }}>{props.includedTitle}</Heading>
                                <Image src={IncludedIcon} />
                            </Flex>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: props.includedNode.childMarkdownRemark.html,
                                }}
                            />
                        </Box>
                        <Box
                            flex={1}
                            mx={[0, 2, 3]}
                            variant="card"
                        >
                            <Flex alignItems="center" mb={3}>
                                <Heading as="h5" variant='subheading' sx={{ flex: 1 }}>{props.notIncludedTitle}</Heading>
                                <Image src={NotIncludedIcon} />
                            </Flex>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: props.notIncludedNode.childMarkdownRemark.html,
                                }}
                            />
                        </Box>
                    </Flex>
                </Box>
            )

        default:
            return null
    }
}

export default Section
