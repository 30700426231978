import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { Box } from 'rebass'
import Section from '../components/base/Section'

export default ({ data }) => (
  <>
    <HelmetDatoCms seo={data.datoCmsPage.seoMetaTags} />
    <Box mb={6}>
      {data.datoCmsPage.sections.map(section => <Section key={section.id} {...section} />)}
    </Box>
  </>
)

export const query = graphql`
  query PageQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
      sections {
        ... on DatoCmsHero {
          id
          background {
            fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          heading
          subheading
          actions {
            ... on DatoCmsProduct {
              id
              name
              slug
              model {
                apiKey
              }
            }
            ... on DatoCmsCategory {
              id
              name
              slug
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsDescription {
          id
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContent {
          id
          title
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
